define("mycare-ember/components/file-upload/component", ["exports", "ember-uploader", "mycare-ember/config/environment"], function (exports, _emberUploader, _environment) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberUploader.default.FileField.extend({
        url: '', //serverUrl used to upload
        uploadedUrl: '', //server returned url where the uploaded image can be accessed
        files: [],
        errors: [],
        isImage: false,
        precentage: 0,

        //Ajax header for authorization
        headers: {
            "Authorization": "Bearer " + localStorage.getItem(_environment.default.APP.AUTH_TOKEN_KEY)
        },

        fileTypeAllowList: function () {
            if (this.get('isImage')) {
                return ['png', 'jpg', 'jpeg', 'gif'];
            } else {
                return ['pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg', 'gif'];
            }
        }.property('isImage'),

        click: function click() {
            this.set('files', []);
            this.get('errors').clear();
            this.sendAction('emptyerrors');
        },

        //Callback for when file is selected.
        filesDidChange: function () {
            var self = this;
            var files = self.get('files');
            self.get('errors').clear();
            //Add custom headers to Ajax Filter. In our case, this should be Authorization header.
            Ember.$.ajaxPrefilter(function (options) {
                options.headers = self.get('headers');
            });

            //Make sure file is uploaded with the 'key' name
            Ember.$('input').attr('name', 'file');

            //Create uploader with given upload url
            var uploadUrl = this.get('url');
            var uploader = _emberUploader.default.Uploader.create({
                url: uploadUrl
            });

            uploader.on('progress', function (e) {
                self.set("precentage", e.percent);
            });

            //Whenever user selected a file
            var file = files[0];
            if (file) {
                var checkResult = self.fileCheck(file);
                var checkFilesize = self.isFileTooLarge(file.size);

                if (checkResult !== true) {
                    self.get('errors').pushObject(checkResult);
                    return;
                }

                if (checkFilesize !== true) {
                    self.get('errors').pushObject(checkFilesize);
                    return;
                }
                if (checkResult === true && checkFilesize === true) {
                    uploader.upload(file).then(function (data) {
                        if (self.isDestroyed) {
                            return;
                        }
                        var thisFileName = String(file.name);
                        //set uploadedUrl
                        var uploadedUrl = String(data.media[0].url);
                        var fileType = String(data.media[0].type);

                        self.set('uploadedUrl', uploadedUrl);
                        self.set('fileName', thisFileName);
                        if (self.action) {
                            var uploadedFile = Ember.Object.create({
                                type: fileType,
                                url: uploadedUrl,
                                fileName: thisFileName
                            });
                            self.sendAction('action', uploadedFile);
                        }
                    }, function () {
                        toastr.error('Error uploading your file');
                    }).finally(function () {
                        self.set('precentage', 0);
                    });
                }
            }
        }.observes("files"),

        fileCheck: function fileCheck(file) {
            if (!Ember.isEmpty(file) && this.get('fileTypeAllowList').length > 0) {
                var fileExtention = file.name.split('.').pop().toLowerCase();
                var isFileTypeValid = false;
                for (var i = 0; i < this.get('fileTypeAllowList').length; i++) {
                    if (this.get('fileTypeAllowList')[i].indexOf(fileExtention) === 0) {
                        isFileTypeValid = true;
                    }
                }

                if (!isFileTypeValid) {
                    var fileTypeAllowListString = "";
                    for (var j = 0; j < this.get('fileTypeAllowList').length; j++) {
                        this.get('fileTypeAllowList').objectAt(j);
                        fileTypeAllowListString += ' ' + this.get('fileTypeAllowList').objectAt(j);
                    }
                    return "Only the following file types can be uploaded:" + fileTypeAllowListString;
                } else {
                    return true;
                }
            } else {
                return "Your file is empty.";
            }
        },

        isFileTooLarge: function isFileTooLarge(fileSize, limitSize) {
            if (!limitSize) {
                limitSize = 4000000;
            }

            if (fileSize < limitSize) {
                return true;
            } else {
                return "The file size can not exceed " + limitSize / 1000 / 1000 + "MB.";
            }
        }
    });
});