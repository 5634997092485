define("mycare-ember/models/job-listing", ["exports", "ember-data"], function (exports, _emberData) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        person: _emberData.default.belongsTo('person', { async: true, inverse: 'jobListings' }),
        isPublished: _emberData.default.attr('boolean'),
        jobType: _emberData.default.attr('string'),
        title: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        mainImage: _emberData.default.belongsTo('image', { async: true }),
        images: _emberData.default.hasMany('image', { async: true }),
        videos: _emberData.default.hasMany('video', { async: true }),
        files: _emberData.default.hasMany('file', { async: true }),
        hourlyRate: _emberData.default.belongsTo('hourly-rate', { async: true }),
        hourlyRateAmount: _emberData.default.attr('string'),
        location: _emberData.default.attr('string'),
        latitude: _emberData.default.attr('number'),
        longitude: _emberData.default.attr('number'),
        startsOn: _emberData.default.attr('string'),
        createdOn: _emberData.default.attr('string'),
        closesOn: _emberData.default.attr('string'),
        publishedOn: _emberData.default.attr('string'),
        endsOn: _emberData.default.attr('string'),
        modifiedOn: _emberData.default.attr('string'),
        languages: _emberData.default.hasMany('language', { async: true }),
        availabilities: _emberData.default.hasMany('availability', { async: true }),
        requirements: _emberData.default.hasMany('requirement', { async: true }),
        services: _emberData.default.hasMany('service', { async: true }),
        applications: _emberData.default.hasMany('job-application', { async: true }),
        equipment: _emberData.default.hasMany('equipment', { async: true }),
        employmentTypes: _emberData.default.hasMany('employment-type', { async: true }),
        interests: _emberData.default.attr('string'),
        relatedOrganisers: _emberData.default.attr('string'),
        workerContactCount: _emberData.default.attr('number'),
        helpRequired: _emberData.default.attr('string'),
        idealWorker: _emberData.default.attr('string'),
        when: _emberData.default.attr('string'),
        hours: _emberData.default.attr('number'),
        hoursPerWeek: _emberData.default.belongsTo('hours-per-week', { async: true }),
        activityTypes: _emberData.default.hasMany('activity-type', { async: true }),
        createdPersonFirstName: _emberData.default.attr('string'),
        createdPersonAvatarUrl: _emberData.default.attr('string'),
        createdPersonJoinedOn: _emberData.default.attr('string'),
        createdPersonLocation: _emberData.default.attr('string'),
        createdPersonId: _emberData.default.attr('number'),
        favouriteBy: _emberData.default.hasMany('person', { async: true, inverse: 'favouriteJobListings' }),
        isShareable: _emberData.default.attr('boolean'),
        isRequiredMoj: _emberData.default.attr('boolean'),
        isHourlyRate: _emberData.default.attr('boolean'),
        isDSSAccount: _emberData.default.attr('boolean'),

        isArchived: function () {
            var self = this;
            if (self.get('closesOn') === undefined || self.get('closesOn') === null) {
                return false;
            } else {
                return moment().isAfter(moment(self.get('closesOn')).endOf('day'));
            }
        }.property('closesOn'),

        isClosed: function () {
            return moment().isAfter(moment(this.get('closesOn')).endOf('day'));
        }.property('closesOn'),

        isExpiresInThreeDays: function () {
            return moment.utc(this.get('closesOn')).subtract('3', 'days').isBefore(moment());
        }.property('closesOn'),

        daysToClose: function () {
            return moment.utc(this.get('closesOn')).startOf('day').fromNow();
        }.property('closesOn'),

        getHoursPerWeek: function () {
            if (Ember.isEmpty(this.get('hours')) === false && this.get('hours') !== 0) {
                return this.get('hours');
            } else {
                if (Ember.isEmpty(this.get('hoursPerWeek')) === false) {
                    return this.get('hoursPerWeek.title');
                }
                return null;
            }
        }.property('hoursPerWeek', 'hours'),

        shareUrl: function () {
            if (this.get('env').isMobile()) {
                return "https://app.mycare.co.nz/browse/jobs/" + this.get('id');
            }
            var browserHost = window.location.host;
            if (browserHost.match(/localhost/) !== null) browserHost = 'test.mycare.co.nz';
            return window.location.protocol + "//" + browserHost + "/browse/jobs/" + this.get('id');
        }.property('id'),

        shareQuote: function () {
            return "Check out my listing on Mycare: \n " + this.get('description');
        }.property('description'),

        canBeShared: function () {
            return this.get('isPublished') === true && this.get('isClosed') === false && this.get('isArchived') === false;
        }.property('isPublished', 'isClosed', 'isArchived'),

        hasBasicInfo: function () {
            return this.get('title') && this.get('description') && this.get('location');
        }.property('title', 'description', 'location'),

        hasPrice: function () {
            return (this.get('hourlyRateAmount') >= 23 || this.get('hourlyRateAmount') == 0) && !Ember.isEmpty(this.get('hourlyRateAmount')) && !Ember.isEmpty(this.get('hoursPerWeek'));
        }.property('hourlyRateAmount', 'hoursPerWeek'),

        hasServices: function () {
            return this.get('services.length') > 0;
        }.property('services.[]'),

        hasActivityTypes: function () {
            return this.get('activityTypes.length') > 0 || this.get('helpRequired');
        }.property('activityTypes.[],helpRequired'),

        hasExperiences: function () {
            return this.get('equipment.length') > 0 || this.get('requirements.length') > 0 || this.get('languages.length') > 0 || this.get('idealWorker.length') > 0;
        }.property('equipment.[]', 'requirements.[]', 'languages.[]', 'idealWorker.[]'),

        hasAvailabilities: function () {
            return this.get('availabilities.length') > 0 || this.get('when');
        }.property('availabilities.[],when'),

        hasRequiredMoj: function () {
            return !Ember.isEmpty(this.get('isRequiredMoj')) && this.get('isRequiredMoj') == true;
        }.property('isRequiredMoj')
    });
});