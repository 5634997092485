define('mycare-ember/seekers/findhelp/job-listings/create/price/controller', ['exports', 'mycare-ember/seekers/findhelp/job-listings/controllers/price'], function (exports, _price) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _price.default.extend({
        setup: function setup() {
            this.set('formData.hourlyRateAmount', this.get('jobListing.hourlyRateAmount') ? this.get('jobListing.hourlyRateAmount') : 23);
            this.parentSetup();
        }
    });
});