define('mycare-ember/models/mileage', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        startLocation: _emberData.default.attr('string'),
        startLatitude: _emberData.default.attr('number'),
        startLongitude: _emberData.default.attr('number'),
        endLocation: _emberData.default.attr('string'),
        endLatitude: _emberData.default.attr('number'),
        endLongitude: _emberData.default.attr('number'),
        distance: _emberData.default.attr('number'),
        rate: _emberData.default.attr('number'),
        timesheet: _emberData.default.belongsTo('timesheet', { async: true }),
        createdPerson: _emberData.default.belongsTo('person', { async: true }),

        total: function () {
            return Number(this.get('distance')) * 0.73;
        }.property('distance')
    });
});