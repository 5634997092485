define("mycare-ember/components/mc-search-bar/slider/component", ["exports", "mycare-ember/utils/common", "mycare-ember/utils/mc-component", "mycare-ember/utils/function-helpers"], function (exports, _common, _mcComponent, _functionHelpers) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcComponent.default.extend({
        tagName: '',

        init: function init() {
            this._super.apply(this, arguments);
            this.initVar('parentId', _common.default.createGuid());
            this.initVar('buttonId', _common.default.createGuid());
            this.initVar('isVolunteer', false);
            this.initVar('min', 23);
            this.initVar('max', 45);
            this.initVar('defaultMin', 23);
            this.initVar('selectMin', 23);
            this.initVar('selectMax', 45);
            this.initVar('defaultMax', 45);
            this.initVar('_shouldRender', false);
        },


        buttonSelectionText: function () {
            if (this.get('isVolunteer')) {
                return 'Volunteer';
            } else {
                return "$" + parseFloat(this.get('selectMin')).toFixed(0) + " - $" + parseFloat(this.get('selectMax')).toFixed(0);
            }
        }.property('isVolunteer', 'selectMin', 'selectMax'),

        isShowButtonText: function () {
            return parseFloat(this.get('selectMin')) !== parseFloat(this.get('defaultMin')) || parseFloat(this.get('selectMax')) !== parseFloat(this.get('defaultMax')) || this.get('isVolunteer');
        }.property('selectMin', 'selectMax', 'isVolunteer'),

        didRender: function didRender() {
            Ember.$('#' + this.get('parentId')).on('hide.bs.dropdown', function () {
                return false;
            });
            this.set('isComponentReady', true);
        },


        actions: {
            open: function open() {
                this.set('_shouldRender', true);
                Ember.$('.dropdown.open').removeClass('open');
                Ember.$('#' + this.get('parentId')).addClass('open');
            },
            onUpdateMin: function onUpdateMin(value) {
                if (this.get('isComponentReady') === true) {
                    this.set('selectMin', value);
                }
            },
            onUpdateMax: function onUpdateMax(value) {
                if (this.get('isComponentReady') === true) {
                    this.set('selectMax', value);
                }
            },
            applyHourlyRate: function applyHourlyRate() {
                _functionHelpers.default.callOrIgnore(this.get('setRate'), {
                    max: this.get('selectMax'),
                    min: this.get('selectMin'),
                    isVolunteer: this.get('isVolunteer')
                });
                this.set('isUpdated', true);
                Ember.$('.dropdown.open').removeClass('open');
            },
            cancelHourlyRate: function cancelHourlyRate() {
                this.set('selectMin', this.get('min'));
                this.set('selectMax', this.get('max'));
                this.set('isVolunteer', false);
                this.set('isUpdated', false);
                Ember.$('.dropdown.open').removeClass('open');
            },
            toggleVolunteer: function toggleVolunteer() {
                this.toggleProperty('isVolunteer');
            }
        }
    });
});