define('mycare-ember/components/mc-booking/expenses-breakdown-modal/component', ['exports', 'mycare-ember/utils/mc-modal-component'], function (exports, _mcModalComponent) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _mcModalComponent.default.extend({
        _wizardService: Ember.inject.service('utils/booking-wizard'),

        init: function init() {
            this.set('_componentId', 'mc-booking/expenses-breakdown-modal');
            this._super.apply(this, arguments);
            this.set('_isEnabledMilage', undefined);
            this.set('_isEnabledExpense', undefined);
            this.set('_maxMileageDistance', undefined);
            this.set('_milageSubTotal', undefined);
            this.set('_totalMycareMileageFee', undefined);
            this.set('_milageTotal', undefined);
            this.set('_milageSummaryTotal', undefined);
            this.set('_maxExpenseAmount', undefined);
            this.set('_totalExpenseTotal', undefined);
            this.set('_expensesSummaryTotal', undefined);
            this.set('_totalMycareExpenseFee', undefined);
            this.set('_totalPayment', undefined);
            this.set('_totalNumberOfTimesheetsForAWeek', undefined);
        },
        _onBeforeOpen: function _onBeforeOpen(options) {
            this.set('_maxExpenseAmount', options.maxExpenseAmount);
            this.set('_isEnabledMilage', options.isEnabledMilage);
            this.set('_isEnabledExpense', options.isEnabledExpense);
            this.set('_maxMileageDistance', options.maxMileageDistance);
            this.set('_milageSubTotal', options.milageSubTotal);
            this.set('_totalMycareMileageFee', options.totalMycareMileageFee);
            this.set('_milageTotal', options.milageTotal);
            this.set('_milageSummaryTotal', options.milageSummaryTotal);
            this.set('_totalExpenseTotal', options.totalExpenseTotal);
            this.set('_expensesSummaryTotal', options.expensesSummaryTotal);
            this.set('_totalMycareExpenseFee', options.totalMycareExpenseFee);
            this.set('_totalPayment', options.totalPayment);
            this.set('_totalNumberOfTimesheetsForAWeek', options.totalNumberOfTimesheetsForAWeek);
        }
    });
});